import React from "react";
import { Link } from "react-router-dom";
import { StartToday } from "../../HomePage";
import { EssentialBuilding } from "../EssentialBuilding";
import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <>
      <StartToday />
      <EssentialBuilding />
      <footer className={styles.footer}>
        <div className={`container ${styles.footerContainer}`}>
          <div className={styles.footerLeft}>
            <div className={styles.footerLinks}>
              <h4 className="font18 poppinsbold textfff mb30">Company</h4>
              <ul className={styles.footerLink}>
                <li>
                  <Link to={"/about-us"} relative="path">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to={"/partner"} relative="path">
                    Partner
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://saleassist.ai/Privacy-Policy"}
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://saleassist.ai/Terms-Conditions"}
                    target="_blank"
                  >
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.footerLinks}>
              <h4 className="font18 poppinsbold textfff mb30">Features</h4>
              <ul className={styles.footerLink}>
                <li>
                  <Link to={"/vidsell"} relative="path">
                    VidSell
                  </Link>
                </li>
                <li>
                  <Link to={"/reels"} relative="path">
                    Reels
                  </Link>
                </li>
                <li>
                  <Link to={"/strike"} relative="path">
                    Strike
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.footerLinks}>
              <h4 className="font18 poppinsbold textfff mb30">Resources</h4>
              <ul className={styles.footerLink}>
                <li>
                  <a
                    href="https://docs.saleassist.ai/"
                    target="_blank"
                    rel="noreferrer"
                    className="font14 footerGray lineHeight30"
                  >
                    Knowledgebase
                  </a>
                </li>
                <li>
                  <a
                    href="https://platform.saleassist.ai/redoc"
                    target="_blank"
                    rel="noreferrer"
                    className="font14 footerGray lineHeight30"
                  >
                    API Docs
                  </a>
                </li>
                <li>
                  <a
                    href="https://saleassist.ai/blogs"
                    target="_blank"
                    rel="noreferrer"
                    className="font14 footerGray lineHeight30"
                  >
                    Blogs
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.footerApps}>
              <div className={styles.isoBanner}>
                <img src="/assets/images/ico-banner.png" alt="iso img" />
              </div>
              <div className={styles.appLinks}>
                <Link to={"https://play.google.com/store/apps/details?id=ai.saleassist.android&hl=en&gl=US&pli=1"} target="_blank">
                  <img
                    src="/assets/images/google-play.png"
                    alt="google play logo"
                  />
                </Link>
                <Link to={"https://apps.apple.com/us/app/saleassist-agent-app/id1665439385"}  target="_blank">
                  <img
                    src="/assets/images/app-store.png"
                    alt="app store logo"
                  />
                </Link>
                <div>
                  <div id="support-code"></div>
                  <button style={{
                    backgroundColor: "#f9e24f",
                    color: "black",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }} id="generate-button" onclick="showSessionCode();">Generate Cobrowse Code</button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerRight}>
            <div className={styles.footerAddress}>
              <h4 className="font18 poppinsbold textfff mb30">Resources</h4>
              <div className={styles.adressBox}>
                <div className={styles.address}>
                  <figure>
                    <img
                      src="/assets/images/usa.png"
                      className="imgResponsive"
                      alt="flag-usa"
                    />
                  </figure>
                  <div className={styles.addressText}>
                    <h5 className="font14 poppinsbold textfff mb10">USA</h5>
                    <p className="font14 textfff lineHeight20">
                      Pobox #438, MORRISVILLE,
                      <br />
                      North Carolina, 27560.
                    </p>
                  </div>
                </div>
                <div className={styles.address}>
                  <figure>
                    <img
                      src="/assets/images/india.png"
                      className="imgResponsive"
                      alt="india flag"
                    />
                  </figure>
                  <div className={styles.addressText}>
                    <h5 className="font14 poppinsbold textfff mb10">India</h5>
                    <p className="font14 textfff lineHeight20">
                      1/22, 2nd Floor, Asaf Ali Road,
                      <br />
                      Delhi, 110002.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`container ${styles.copyRight} ${styles.copyRight1}`}>
          <p>© 2022 Saleassist.ai. All rights reserved.</p>
          <ul className={styles.termsAndCondition}>
            <li>
              <Link to={"https://saleassist.ai/Privacy-Policy"} target="_blank">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to={"https://saleassist.ai/Terms-Conditions"}
                target="_blank"
              >
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};
